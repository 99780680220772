
@import '~ol/ol.css';


html,
body,
#root,
#root > div {
    height: 100%;
    width: 100%;
    margin: 0;
}



.iframe-container{
    width : 100%;
    height : 100vh;
    overflow :  hidden;
}  
