
/************************* Map ***********************/

.map-container {
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  .map {
  width: 100%;
  height: 70%;
  }
  
  
  .ol-zoom{
  bottom: 40px ;
  top: auto !important ; 
  right: 20px ;
  left: auto !important; 
  }
  
  .ol-attribution.ol-uncollapsible{
  visibility: hidden;
  }
  
  ul.gcd-gl-result{
  transform: translate(-72px, 3px);
  width: 265px !important;
  }
  
  .btn-popup{
  width: 100%;
  background-color: #4c99e7;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  }
  
  .label-popup{
  width: 100%;
  padding:0px 0px 20px 0px;
  }
  
  .popup-signin-container{
   background-color: white;
    text-align: left;  
    width: 350px;
    padding: 30px 30px 30px 30px;
  }
  
  
  .ol-control  {
  background-color: transparent !important;
  }
  
  .ol-geocoder .gcd-gl-control {
  width: 300px !important;
  height: 35px !important;
  text-align: center;
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: -75px !important;
  }
  
  .ol-geocoder .gcd-gl-control > .gcd-gl-input{
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  border : 1px solid gray;
  padding: 0 10px !important;
  }
  
  .ol-geocoder .gcd-gl-input {
  width: 235px !important;
  }
  
  .ol-geocoder .gcd-gl-btn{
  position: unset !important;  
  width: 2rem !important;
  display: flex; 
  justify-content: center;
  align-items: center;
  }
  .ol-geocoder .gcd-gl-reset {
  right: 30px !important;
  
  }
  .ol-geocoder .gcd-gl-btn,
  .ol-geocoder .gcd-gl-input{
  height: 30px !important;
  
  }
  
  #ol-geocoder #gcd-gl-expanded{
  width: 200px;
  }
  
  .search-bar-container {
    display: flex;
    align-items: center;
    background-color: #fff;
  }
  
  .search-input{
    outline: none;
    border  : none;
    width: 400px;
  }
  
  .search-icon{
  padding-left: 5px;
  margin-top: 3px;;
  }
  
  .icon-container{
  position: absolute;
  width: auto;
  top: 10px;  
  display: flex;
  padding-top: 11px;
  padding-right: 25px;
  align-content: space-between;
  cursor: pointer;
  right: 10px;
  z-index: 1;
  }
  
  .icon-visible{
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    margin-top: -2px;
  }
  
  
  
  .login-container{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative; 
  }
  
  
  .login-user{
  position: fixed;
  margin-right: 35px;
  right: 0px;
  }
  
  .dropdown-menu{
  background-color: rgb(243, 247, 247);
  margin-top: 40px;
  list-style: none;
  padding: 10px ;
  border-radius: 7px;
  text-align: left;
  }
  
  li{
  padding: 2px;
  }
  
  .user-icon{
  margin-top:-3px;
  }
  
  .close-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-weight: 1;
  color: aliceblue;
  padding: 30px;
  box-sizing: border-box;
  }
  
  
  .mouse-position {  
  position: fixed; 
  bottom: 12px; 
  left: 12px; 
  color: #fff;
  padding: 10px;
  justify-content: space-evenly;
  width:160px;
  border-radius: 4px; 
  opacity: 0.7;
  }
  
  .close-icon {
    margin: 0;
  }
  
  .star-container {
  margin-left: -265px;
  position: absolute;
  }
  
  
  .search-bar {
  position: fixed;
  width: 310px;
  top: 15px;
  margin-left: 18px;
  }
  
  .search-bar > .searchbar-container {
    display: flex; 
    background-color: #141313; 
    border-radius : 5px; 
    padding :5px;
  }
  
  .search-bar input {
  width: 100%;
  padding: 8px;
  outline: 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  color: white;
  background-color: #141313;
  }
  
  .suggestions {
  position: absolute;
  border-top: none;
  width: 100%;
  max-height: 160px;
  overflow-y: auto;
  z-index: 1000;
  list-style-type: none;
  margin-top: 8px;
  border-radius: 5px;
  background-color: #141313;
  font-size: 14px;
  color: white;
  padding: 0px 0px 0px 3px;
  }
  
  .suggestions li.highlighted {
  background-color: #302e2e;
  }
  
  .suggestions li {
  padding: 8px;
  cursor: pointer;
  }
  
  .suggestions li:hover {
    border-radius: 5px;
    background-color: #302e2e;
  }
  
/********************************* SidePanel ***************************/

:root{
    --label--color:red;
    --label--color2:green;
    --label--color-common : #212930;
    --label-color-panel : #9fb4c7;
    --button-color-common : #4c99e7;
    --common-white : #fff;
    --field--bordercolor : rgb(159, 163, 167,0.5);
    --field--background : #d4d4d4;
  }
  
  .tip-sidepanel{
     color: var(--label-color-panel);
  }
  
  .sidepanel-filter {
    padding-top: 50px !important;
  }
  
  .side-panel {
    width: 350px; 
    height:100vh; 
    background-color:var(--common-white) ; 
    position: fixed;
    top: 50px;
    padding: 20px;
    right: 0;
    border-radius: 4px;
    transition: transform 0.3s ease-in-out; 
    color: var(--common-white);
    background-color: var(--label--color-common);
    
  }
  
  
  @media screen and (max-width: 1024px) {
      .side-panel {
           width: 304px;
      }
  }
  
  @media screen and (max-width: 1440px) {
      .side-panel {
        width: 320px;
      }
  }
  
  .season-child-container{
      height: 45px;
  }
    
  .polygon-div-container {
      margin-left:-30px;
  } 
     
  .interest-container{
     padding-top: 25px;
  }
  
  .side-panel.collapsed {
     transform: translateX(100%);
  }
  
  .svg-polygon, .custom-polygon{
      width: 150px;
      padding: 5px;
      stroke: #90979e;
  }
  
  .svg-polygon{
      display: flex;
      max-height: 75px;
      stroke-width: 85px;
      max-width: 120px;
      padding-top: 7px;
      fill: transparent;
  }
  
  .label-area {
      text-align: center;
      left: 0;
      font-weight: bold;
      font-size: 20px;
      position: absolute;
      right: 0;
  }
  
  .label-area-panel{
    float: left;
    margin-bottom: 5px;
  }
   
  .close-icon{
    float: right;
  }
  
  .select-custom {
    margin-top: -8px;
    width: 100%;
    border-radius: 5px;
    display: flex;
  }
  
  .btn-add{
    font-size: 15px;
    font-weight: bold;
  }
  
  select{
    padding: 10px;
    width: 100%;
    border-radius: 5px;
  }
  
  .input-area, .input-area-coordinate {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  
  .input-area-coordinate {
    height: 60px;
    width: 100%;
    margin-top: 10px;
    resize: none;
    overflow: auto;
    margin-bottom: 10px;
  }
  
  .btn-save, .btn-cancel, .btn-Add-field{
      color: var(--common-white);;
      padding: 14px 20px;
      margin: 8px 0;
      border-radius: 2px;
      border: none;
      font-weight: 600;
      width: 100%;
      border-radius: 10px;
    }
  
  .btn-save {
      cursor:default;
      background-color: #b1b6bb;
    }
  
  .btn-Add-field{
      background-color: var(--button-color-common);
    }
  
  .btn-cancel{
      cursor: pointer;
      background-color: var(--button-color-common);
    }
  
  .field-list-polygon{
      margin: 10px;
      display: flex;
      border-radius: 15px;
      padding: 10px;
  }
  
  .field-parameter-column{
    width: 100%;
  }
  
  .field-list-container{
     background-color:var(--common-white);;
     width: 20%;
  }
  
  .label-container{
     display: flex;
     width: 100%;
     justify-content: space-between;
  }
   
  hr.div-hori-line{
    border: 0.1px solid rgb(8, 8, 8);
  }
  
  .field-listView-container-overview, .field-listView-container{
    border-color: var(--field--bordercolor);
    border-width: 0.1px;
    border-style: solid; 
    border-radius: 5px; 
    cursor: pointer;
    margin: 5px;
  }
  
  .field-listView-container{           
    background-color: var(--field--background);
    height: 80vh;
    overflow: hidden;
    background-color:var(--label--color-common) ;
    position: relative;
  }
  
  

/*************************** Crop Monitor ***********************/

.container1, .container1-season-temp{   
    background-color: #323d49;
    text-align: left;
    padding: 40px;
    border-radius: 7px;
  }

  .required-label{
    color:red;
    font-size: 13px;
  }


@media screen and (max-width : 1440px) {
  .outer-container1{
    width: 30% !important;
  }
  .szh-menu-container{
    margin-left: -63px ;  
  }
}

.season-panel > .season-label, .addveg-label {

  font-weight: 400; 
  font-size :14px; 
}

.table-cropwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh ;
}

.season-label{
  color: white;
  margin-left:10px; 
  text-align: left;
  width: 100%; 
  margin-top : 4px; 
}

.addveg-label {
  color: #3592fd ;
  cursor: pointer;
  letter-spacing: 0.4px;
}

.keyboard-expansion-panel{
  color: white;
  cursor: pointer;
}

.addveg-expansion-panel-label {
  padding-bottom: '20px'; 
  display: flex; 
  justify-content: center; 
  align-items: 'center'; 
  width: 100%;
}

.checkbox-label-container{
  padding: 10px 0px 0px 0px;
}

hr.draw-popup{
  background-color: #b7b9bb;
}

.szh-menu-button {
  box-sizing: border-box;
  background-color: #212930;
  border: none;
  color: white;
  cursor: pointer;
}

.szh-menu-container{
  font-size: 14px;
}

.szh-menu-container ul{
  color: white;
  background-color: rgb(1, 2, 3);
  position: absolute;
  left : 60px !important;
  border-radius: 10px;
  margin-bottom: 10px;
}

.szh-menu__item--hover {
   background-color: #323d49 !important;
}


.container-group-form{
  background-color: #fff; 
}
  
.close-container-season{
  width: 100%;
  justify-content: flex-end;
  display: flex;
}
  
.close-icon{
  text-align: right;
  color: white;
  cursor: pointer;
}

.login-form{ 
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  width: 100%;
  height: 100%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: rgb(36 35 35 / 60%);
  padding: 15px;
  border-radius: 4px;
  z-index : 999;
}

.label-text-group{
  text-align: center;
  font-size: 13px;
  color: #141313;
}

.overview-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  position: fixed;
  left: 50%;
  height: 100%;
  background-color:rgba(0,0,0,.32);
  transform: translate(-50%, -50%);
}

hr.seasno-hr{
  border: 0.1px solid rgb(92, 89, 89);
}


.checkbox-container-season{
  height: 250px;
  overflow: auto;
  margin-top: 10px;
  background-color: #323d49;
}

.check-box {
  padding: 5px;
}

.outer-container1{  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.season{
  width: 100%;
  position: absolute;
  height: 100vh;
  background-color: rgba(0,0,0,.32);
}

.input-Field{
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 4px;
}

.text-area-field{
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: none;
  border-radius: 4px;
  height: 70px;
  resize: none;
}

input[type=submit]{
  background-color: #4c99e7;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  width: 100%;
  padding-left: 30px; 
  border-radius: 8px;
}

.delete-icon-container{
  width: 100%;
  display: flex;
  margin-right: -40px;
  justify-content: right;
}

.custom-select{
  margin: 8px 0;
  width: 100%;
  padding: 8px;
}


.label-text-delete-sowing{
  text-align: center;
  font-size: 15px;
  color: #141313;
  display: flex;
  justify-content: center;
}

.note-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Signin-container{
  text-align: center;
}

.container-lab{
  text-align: center;
  padding: 10px;
}

.Google-icon {
  padding-left: 5px;
}

.forgot-container{
  justify-content: space-between;
  display: flex;
}

.image-container-crop-identify{
  display: flex;
  height: 95vh;
}


#cropdata {
font-family: Arial, Helvetica, sans-serif;
border-collapse: collapse;
width: 100%;
}



#cropdata td, #cropdata th {
border: 1px solid #929090;
padding: 8px;
}


#cropdata th {
padding-top: 12px;
padding-bottom: 12px;
text-align: left;
opacity: 1;
background-color: #757575    

}

.out-contain{
display: flex;
justify-content: center;
align-items: center;
top: 50%;
width: 100%;
height: 100%;
left: 50%;
position: fixed;
transform: translate(-50%, -50%);
background-color: rgb(36 35 35 / 60%);
padding: 15px;
z-index: 999;
border-radius: 4px;
}

thead th {
  position: sticky;
  top: 0;
  margin: 0;
}

th:first-child {
  border-top-left-radius: 10px;
}

tbody tr{
  overflow: auto;
}

.overview-height{
  width: 100%;
  height: auto;
}

.image-classify-container {
  width: 46%;
  height: 95%;
}

.table-classify-container{
  width: 100%;
  margin: 30px;
}

@keyframes scan {
  0% {
      transform: translateY(-100%);
  }
  100% {
      transform: translateY(100%);
  }
}

.scanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 99, 99, 0.5);
  animation: scan 2s linear infinite;
}

.button-container-submit{
    margin-top: 10px;
}

.addbutton-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

#add-text{
  cursor: pointer;
  font-size: 35px;
}

.image-view-container{
  
  height: 100%;
}

.image-view-container input{
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.result-img-viewer{
  padding: 20px;
  background-color: #323d49;
  
}

.image-view-addimage , .image-view-ouput-addimage{
  display: flex;
  background-color: #dae9e3;
  width: 100%;   
  box-shadow:  2px -8px 8px 0 rgba(224, 222, 222, 0.2), 0 6px 20px 0 rgba(253, 253, 253, 0.19);
  position: relative;
  height: 100%;
}

.image-view-addimage{
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.inner-container{
  width: 100%;
  padding: 10px;
}

.image-label-data{
  width: 100%;
}

.image-container-label{
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container, .image-container-label{
  padding: 30px;
  width: 50%;
  color: black;
}

.result-image-viewer{
  display: flex;
  width: 100%;
}

.result-image-viewerContainer{
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #3592fd;
}

.card-imageview-submit , .card-imageview-output{
  width: auto;
  margin: 10px;
  height: 46%;   
}

.image-classify-container1{
  width: 60%;
  padding: 20px;
}

.crop-identify-container{
  width: 90%; 
  height:100vh; 
  background-color: #f0f0f0; 
  padding: 25px;
  transition: transform 0.3s ease-in-out;  
  color: #e1dddd;
  background-color: #212930;     
}

.season-container
{
  height: 70%;
  overflow: auto;
  margin-top: 15px;
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .season-container {
    max-height: 40vh; 
    min-height: 200px; 
    overflow-y: auto;
    margin-top: 10px; 
    width: 100%; 
  }
}

@media screen and (max-width: 1024px) {
  .season-container {
    max-height: 45vh;
    min-height: 250px; 
    margin-top: 12px;
    width: 100%; 
  }
}

.inputContainer, .drawpopContainer {
  position: relative;    
  align-items: center;
  border-radius:2px;
}

.inputContainer{
  display: flex;
}

.drawpopContainer > .drawpopupflex-container{
  width: 100%; 
  text-align: center; 
  margin-top: 20px; 
  border-radius: 10px; 
  color: white;
}

.cancel-button-popup {
  background-color: rgb(130 137 145);
  cursor: pointer; 
  margin-right: 10px;
}

.common-popup-button {
  background-color: #4c99e7; 
  cursor: pointer;
}

.drawpop-icon-wrapper{
  margin-top: 20px;
}

.drawpop-header-label{
  color: white; 
  width: 100%; 
  text-align: center;
}

.drawpopContainer > .drawpopup-label-wrapper, 
  .drawpopup-button-wrapper,.drawpop-drawlabel-wrapper {
      padding: 10px;
}

.drawpopup-button-wrapper{
  display: flex;
}

.drawpop-drawlabel-wrapper{
  color: #6e7b7b;
}

.drawpopContainer{
    display: block;
}

.inputIcon {
  position: absolute;
  right: 10px;
}


.first-last-container {
  justify-content: space-between;
  display: flex;
}

.nest-container-first {
  width: 100%;
}

.nest-container-last {
  width: 100%;
  padding-left: 10px;
}


.main-section-container{
    display: flex;
}


.main-container{
    width:65%; 
    height: auto;
    border-radius: 20px; 
    z-index: 0;    
}

@media screen and (max-width: 1440px) {
  .main-container{
    width:90%; 
    z-index: 0;  
    height: auto;
    border-radius: 20px;     
}      
}.edit-text


.main-container-design{
  background-color: #a4a8ac;
  justify-content: center;
  
  border-radius: 10px;
}

.label-text-sowing,.label-text-season{
  color: white;
}

.edit-field-label, .season-field-label{     
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 500;  
}

.edit-field-label{
  background-color: #212930;
  padding: 25px;
}

.save-cancel-container{
  display: flex;
}

.season-formdata-container{
  width: 100%;
  border: 0.1px solid;
  border-color: rgb(159, 163, 167,0.5);
  border-radius: 5px;
}

.season-expansion{
  width: 56%;
}

.season-column-container{
  width: 100%;
}

.column{
  width: 100%;
  margin: 5px;
  font-size: 14px;
}

.save-button, .cancel-button, .delete-button, .submit-button { 
  border: none;
  color: white;
  padding: 15px 32px;
  width: 220px;
  margin-right: auto;
  border-radius: 5px;
  text-decoration: none;       
  float: right;
  direction: rtl;
  cursor: pointer;
}

.save-button, .cancel-button{
  margin: 4px 8px;
}

.submit-button{
    margin-bottom: 10px;
}

.cropIdentify-toast-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: 'center';
  padding: 20px; 
}

.cropIdentify-label-container label{
  font-size: 15px;
}

.cropIdentify-toast-container label{
  color: black; 
  width: 100%; 
  text-align: center;
}

.cropIdentify-label-container {
   width: 100%; 
   display: flex; 
   padding: 10px;
   justify-content: space-between;
}

#image-source{
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.image-classify-tablecontainer {
   width: 100%;
   position: relative; 
   overflow: auto;
   height: 100%;
}

#img-table-preview{
  max-width: 50px;
  max-height: 50px;
}

#image-preview-td{
  width: 130px;
  height: 50px;
}

td{
  text-align: center;
}

.image-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-button, .submit-button{
  background-color :#3592fd;
}

.cancel-button, .delete-button{
  background-color: #777777 ;
}

.polygon-custom{
  stroke-width: 50;
}

.back-button-container{
  padding-bottom: 10px;
}

.ul-field-list{
  padding: 0;
}

li{
  list-style:none;
}
.delete-container{
  display: flex;
}


.save-button:hover, .cancel-button:hover, .delete-button:hover {
  background-color: #868583  ;
}

.main-section-sowing-container{
  padding: 10px; 
  background-color: #181f26;
  
}

.season-forn-container{
  display: flex;
  padding: 10px;
}

.season-panel{  
    padding: 9px;
    display: flex;
}

.season-main-expansion-container{
  border: 1px solid #323d49;
  height: 75px;
  margin :10px;   
}

.sowing-expansion-panel{
  padding-bottom: 5px;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 8px;
}

.sidepanel-field-container{
  overflow: auto;
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

.label-season{
  font-size: 14px;
  margin-left: 8px;
  color: #fff;
}

.label-container-season.label-season{
  color: #3592fd;
}

@media screen and (max-width: 1650px)   {
  .main-container {
    width: 75%;
    z-index: 0;  
  }

  .main-section-container .crop-rotation-label{
    height:400px !important;
  }
  
  @media screen and (max-width: 1440px) {
    .main-section-container .crop-rotation-label {
      height: 250px !important;
    }
  }
  .container1-season-temp{
    padding: 40px !important;
    width: 100%;
  } 

  .crop-rotation-label {
    overflow: auto;
    padding: 15px;        
    height: 400px !important;      
  } 
}

@media screen and (max-width: 1240px)   {
  .main-container {
    width: 94%;
    z-index: 0;  
  }

  .main-section-container .crop-rotation-label{
    height:330px !important;
  }
  .map-section-container{
    width: 50% !important;
  }
  .side-panel{
    width: 300px;
  }

  }

  .season-expansion-notes{
    width: 100% !important;
  }
  
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.crop-rotation-label{
  overflow: auto;
  padding: 15px;
  height: 450px;
}

.season-expansion-panel{
  min-height: 48px;
  border-radius: 5px;
  background-color: #323d49;
  margin-bottom: 10px;

}

.sowing-expansion-label{
  width: 100%;
  background-color: #c0e617;
  display: flex;
  height: 14px;
  text-align: center;
  overflow: hidden;
}

.sowing-expansion-label.label{
  font-size: 45px;
}

.button-box-delete{
  margin-right: auto;
}

.button-container{
  padding: 15px;
  background-color: #212930;
  display: flex;
}

.field-container{
  display: flex;
  padding-bottom: 17px;
  border-bottom: 1px solid;
  border-color: #574f4f;
}

.Add-season-container{
  margin: 10px 0px; 
}

.map-section-container{
  width: 100%;
}

.Add-season-button {
  padding: 10px 55px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid;
  font-size: 14px;
  color: #3592fd;
  font-weight: 500;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
  letter-spacing: .4px;
  background: rgb(53, 146, 253, 0.2);
}

.field{
  width: 100%;
  padding: 15px 10px;
  margin: 8px;
  border: none;
  outline: none;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #313235;
  color: white;
}

.field-label, .field-label-rotation{
   margin-left: 10px;
}

.field-label{
  font-weight: bold;
}

.label-season-crop{
  border: 1px solid #000;
  padding: 15px;
}

.sesaon-button-container span{
  color: #7e7979; 
  font-weight: bold; 
  cursor: pointer;
} 

.season-expansion  a{
  color:#3592fd;
  text-decoration: none;
  font-weight:400;
  cursor: pointer;
}

.column{
  color:#9fb4c7
}

.season-expansion-outer-container{
  overflow: hidden;
}

.button-daterange button {
  position: fixed;
  margin-right: 20px;
  bottom: 0;
  background-color: var(--button-color-common);
  width: -webkit-fill-available;
  cursor: pointer;
}

.field-btn-add {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 5px ;
}

.sesaon-button-container{
  padding: 10px;
  font-size: 14px;
}

.season-input-checkbox{
  border:none;
   margin-top: 10px;
}

.labelseason-checkbox-container {
    font-size: 15px; 
    margin-left:10px;
}

    
    
/***************************** Navigation Menu *****************************/

.list-custom {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: max-content;
    justify-content: center;
    background-color: #141313;
    position: absolute;
    border-radius: 10px;
    transform: translateX(-50%);
    top: 45%;
    transform: translateY(-50%);
  }
  
  .nest-li a {
    display: flex;
    color: #cfc6c6;
    padding: 15px 16px;
    text-decoration: none;
  }
  
  .nest-li a:hover {
    background-color: #555;
    color: #fff;
    border-radius: 10px;
  }
  
  .list-favorite .highlighted {
    background-color: #1f1e1f;
    padding: 10px;
    color: #057794;
  }
  
  .Nav {
    padding-left: 18px;
  }
  
  
  .Nav .nest-li {
    position: relative;
  }
  
  .Nav .nest-li {
    position: relative;
  
  }
  
  .Nav .nest-li .label {
    display: none;
    position: absolute;
    top: 20%;
    left: 100%;
    font-weight: bold;
    width: 250px;
    margin-left: 135px;
    text-align: left;
    transform: translateX(-50%);
    padding: 5px;
    color: #0a0a0a;
    border-radius: 4px;
  }
  
  .list-main {
    list-style-type: none;
    padding: 0px;
  
  }
  
  .btn-clr {
    margin-top: 10px;
  }
  
  .Nav .nest-li:hover .label {
    display: block;
  }
  
  .checkbox-container {
    display: flex;
  }
  
  .submenu {
    list-style-type: none;
    margin: 0;
    padding: 6px;
    top: -30%;
    height: auto;
    overflow: auto;
    text-align: left;
    margin-left: 55px;
    position: absolute;
    background-color: #333333;
    border-radius: 6px;
  }
  
  .Nav .nest-li:hover .submenu {
    display: block;
  }
  
  .lab-text {
    color: #fff;
  }
  
  .lis {
    padding: 0px;
    list-style-type: none;
  }
  
  .aoi-main:hover {
    background-color: #131010;
    cursor: pointer;
  }
  
  .nav-a-hover {
    cursor: pointer;
  }
  
  .daterange-filter-container {
    display: flex;
    width: 100%;
  }
  
  .daterange-label {
    padding: 10px 0px 10px 0px;
  }
  
  .daterange-filter input {
    padding: 8px;
    border-radius: 5px;
    width: 85%;
  }
  
  .daterange-filter {
    width: 50%;
  }
  
  .list-favorite {
    color: #fff;
    padding: 10px;
    width: max-content;
    cursor: pointer;
  }
  
  .tool-tip-id {
    padding: 0px !important;
  
  }
  
  .op {
    position: absolute;
  }
  
  .sidepanel-tip-container {
    width: 100%;
    text-align: center;
    height: 40%;
    overflow: auto;
    color: #9fb4c7;
    margin-top: 45px;
  }    

  /*********************************  Login *****************************/

.outer-container > .first-last-container {
    justify-content: space-between;
    display: flex;
  }

  .inputIcon {
    position: absolute;
    right: 10px;
  }

  .container-sign-in, .container-signup, .container-group-form, .container-draw-popup{    
    text-align: left;
    width: 350px;
}

.container-draw-popup-aoi{
  width: 370px;
}

.container-sign-in, .container-signup, .container-group-form{
  background-color: rgb(255, 252, 252);
  padding: 45px;
  border-radius: 10px;
}

.container-draw-popup, .container-draw-popup-aoi{
   background-color: #212930;
   padding: 20px;
   border-radius: 10px;
}

@media screen and (max-width : 1440px)  {
  .container-draw-popup, .container-draw-popup-aoi{
    background-color: #212930;
    padding: 30px 30px 30px 30px;
    border-radius: 10px;
 }
}

.inputField{

    width: 100%;
    padding: 10px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 2px;
  }
  
  input[type=submit]{

    background-color: #4c99e7;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    width: 100%;
  
  }

  .nest-container-first, .nest-container-last {
    width: 100%;
   }
   .nest-container-last {
    padding-left: 10px;
   }
   .Google-icon{
    margin-bottom: -5px;
   }

   .label-text{
    text-align: center;
    font-size: 13px;
  }

  .season-scroll-bar-expansion {
    height: 80%;
  }

  .season-expansion-panel-container {
    height: inherit;
  }

 /****************************** Spinner ****************************/

 .loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #212930;
  width: 20px;
  height: 20px;
  position: absolute;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-container{
  width: 100%;
  height: 100%;
}

.spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(255, 255, 255, 0.1);
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.background-season {
  position: relative;
  width: 100%;
  height: 65%;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: center;
}